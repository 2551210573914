.loader-comet {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: inline-block;
    border-top: 2px solid var(--color26);
    border-right: 2px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
