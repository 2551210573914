:root {
    --font-default: 'RFDewi';
    --font-title: 'RFDewiExtended';
}

$font_name: 'RFDewi';
$font_name-2: 'RFDewiExtended';
$default_font: $font_name, sans-serif;
$title_font: $font_name_2, sans-serif;

@font-face {
    font-display: fallback;
    font-family: $font_name;
    src: url('../fonts/' + $font_name + '/' + $font_name + '-400.woff') format('woff'),
        url('../fonts/' + $font_name + '/' + $font_name + '-400.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-display: fallback;
    font-family: $font_name;
    src: url('../fonts/' + $font_name + '/' + $font_name + '-600.woff') format('woff'),
        url('../fonts/' + $font_name + '/' + $font_name + '-600.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-display: fallback;
    font-family: $font_name_2;
    src: url('../fonts/' + $font_name_2 + '/' + $font_name_2 + '-400.woff') format('woff'),
        url('../fonts/' + $font_name_2 + '/' + $font_name_2 + '-400.woff2') format('woff2');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-display: fallback;
    font-family: $font_name_2;
    src: url('../fonts/' + $font_name_2 + '/' + $font_name_2 + '-600.woff') format('woff'),
        url('../fonts/' + $font_name_2 + '/' + $font_name_2 + '-600.woff2') format('woff2');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}