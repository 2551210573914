:root {
    --color1: #ffffff;
    --color2: #f3f8fc;
    --color3: #222222;
    --color4: #595959;
    --color5: #e6f1f8;
    --color6: #c0d3df;
    --color7: #0071bc;
    --color8: #005791;
    --color9: #031a49;
    --color10: #042260;
    --color11: rgba(68, 86, 110, 0.2);
    --color12: #ff6e4e;
    --color13: #44566e;

    --color14: #e6edef;
    --color15: #5e4fbc;
    --color16: #ffe2ab;
    --color17: #cbfdc7;
    --color18: #e2dff1;
    --color19: #f4ffb0;
    --color20: #a9ffa3;
    --color21: #008000;
    --color22: #7765e3;
    --color23: #5442c5;
    --color24: #2bdce3;
    --color25: #42ffdb;
    --color26: #00aae5;
    --color27: #484576;
    --color28: #c1c1c1;
    // --color29: #cbfdc7;
    --color30: #dae6ff;
    --color31: #27315e;
    --color32: #9c9c9c;
    --color33: #eaeef4;
    --color34: #0c0a0a;
    --color35: #dfdfdf;
    --color36: #eeeeee;
    --color37: #cdcdcd;
    --color38: #cccccc;
    --color39: #898599;
    --color40: #3faf3d;
    --color41: #e6ffe4;
    --color42: #eeffe5;
    --color43: #0a86d9;
    --color44: #f1f5f9;
    --color45: #0169ae;
    --color46: #cfecff;
}
