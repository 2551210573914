.loader-standard {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;

    &__loader {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: transparent;
        border: 4px solid transparent;
        border-top-color: var(--color5);
        border-bottom-color: var(--color5);
        -webkit-animation: 1s spin linear infinite;
        animation: 1s spin linear infinite;
    }

    &__fullscreen {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: var(--color1);
        right: 0;
        left: 0;
        z-index: 100;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
