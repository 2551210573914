/* Стилизация scrollbar'а */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--color11) var(--color1);
}

/* Работает в Chrome, Edge и Safari */
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: var(--color1);
}

*::-webkit-scrollbar-thumb {
    background-color: var(--color11);
    border-radius: 20px;
    border: 3px solid var(--color1);
}

.app {
    display: block;
}

.app-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
}
