/* Нормализация стилей */
@import '/src/static/scss/normalize.scss';

/* Подключчение основных шрифтов */
@import '/src/static/scss/fonts.scss';

/* Подключение цветов */
@import '/src/static/scss/colors.scss';

/* Подключение SVG иконок */
@import '/src/static/scss/svg.scss';

/* Подключение scss переменных */
@import '/src/static/scss/variables.scss';

/* Подключение Медиа брейкпоинтов */
@import '/src/static/scss/media_breakpoints.scss';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: var(--white);
    color: var(--color-1);
    font-family: $default_font;
    font-weight: 400;
}

main {
    padding-top: 87px;
}

button {
    font-family: $default_font;
}

//svg:not(:root) {
//    width: 100%;
//    height: 100%;
//}

h2 {
    font-family: var(--font-title), sans-serif;
    font-weight: 400;
    font-size: 40px;
    line-height: 120%;
    color: var(--color9);
}

.title-card {
    font-family: var(--font-title), sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: var(--color9);
    transition: color 0.2s ease-in-out;
}

a {
    // color: var(--color7);
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    &:hover {
        opacity: 0.75;
    }
}

.text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: var(--color13);
}

@media screen and (max-width: 768px) {
    h2 {
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
    }
}
